<template>
  <LeadStatusesPage
    title="Autonomia - Unidades"
    description="Define os status que são considerados ativos para a unidade.
    Esses status são utilizados para definir a carga de serviços"
    param-id="lead_statuses_active_for_unity"
  />
</template>

<script>
import LeadStatusesPage from "@/components/Lead/Statuses/LeadStatusesPage.vue";

export default {
  components: {
    LeadStatusesPage,
  },
};
</script>
